import {
  TEAM_MEMBER_PROFILE_PATH,
  TEAM_MEMBER_NOTIFIC_SETTINGS_PATH,
  TEAM_MEMBER_BANK_ACCOUNTS_PATH,
} from 'constants/routes/teamMember.constants'
import { SALES_TEAM_MEMBER_PROFILE_PATH } from 'constants/routes/salesTeamMember.constants'
import {
  PUBLISHER_BANK_ACCOUNTS_PATH,
  PUBLISHER_PROFILE_PATH,
  PUBLISHER_NOTIFICATIONS_PATH,
} from 'constants/routes/publisher.constants'
import { ELO_PUBLISHER_PROFILE_PATH, ELO_PUBLISHER_BANK_ACCOUNTS_PATH } from 'constants/routes/eloPublisher.constants'
import { NOTIFIC_SETTINGS_PERMISSION_KEY } from 'constants/notifications.constants'

const settingMenuConfigs = {
  seller: [
    {
      title: I18n.t('react.shared.nav.general'),
      ownSidebar: true,
      children: [
        {
          href: '/cabinet/profile/edit',
          title: I18n.t('react.shared.nav.profile'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/billing',
          title: I18n.t('react.shared.nav.billing'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/bank_accounts',
          title: I18n.t('react.shared.nav.settings_tab.bank_accounts'),
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/bank_accounts'],
        },
        {
          href: '/cabinet/notific_types',
          title: I18n.t('react.shared.nav.settings_tab.notific_types'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/team_projects/team_members',
          title: I18n.t('react.shared.nav.roles_permissions'),
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/team_projects/jv_commissions', '/cabinet/team_projects/team_member_roles'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payment_setup'),
      ownSidebar: true,
      children: [
        {
          href: '/cabinet/settings/payment_providers',
          title: I18n.t('react.shared.nav.settings_tab.payment'),
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/settings/payment_settings'],
        },
        {
          href: '/cabinet/invoice_setup',
          title: I18n.t('react.shared.nav.settings_tab.invoice_setup'),
          visibility: ['business'],
        },
        {
          href: '/cabinet/imprint',
          title: I18n.t('react.shared.nav.my_imprint'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/privacy',
          title: I18n.t('react.shared.nav.my_privacy'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/terms',
          title: I18n.t('react.shared.nav.my_tc'),
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.claim_strategy'),
          href: '/cabinet/claim_strategy',
          visibility: ['powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_setup'),
      ownSidebar: true,
      children: [
        {
          href: '/cabinet/integrations',
          title: I18n.t('react.shared.nav.settings_tab.integration'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/domains',
          title: I18n.t('react.shared.nav.settings_tab.domain_settings'),
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/domains/ablefy_subdomain', '/cabinet/domains/custom_domain'],
        },
        {
          href: '/cabinet/email_configurations',
          title: I18n.t('react.shared.nav.settings_tab.email_configurations'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/cookies_consent',
          title: I18n.t('react.shared.nav.settings_tab.privacy_consent'),
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/fonts',
          title: I18n.t('react.shared.nav.custom_font'),
          visibility: ['business', 'powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_legal'),
      ownSidebar: true,
      children: [
        {
          href: '/terms',
          title: I18n.t('react.shared.nav.elopage_tc'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
        {
          href: '/privacy',
          title: I18n.t('react.shared.nav.elopage_privacy_policy'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
      ],
    },
  ],
  publisher: [
    {
      title: I18n.t('react.shared.nav.general'),
      ownSidebar: true,
      children: [
        {
          href: PUBLISHER_PROFILE_PATH,
          title: I18n.t('react.shared.nav.profile'),
        },
        {
          href: PUBLISHER_BANK_ACCOUNTS_PATH,
          title: I18n.t('react.shared.nav.settings_tab.bank_accounts'),
        },
        {
          href: PUBLISHER_NOTIFICATIONS_PATH,
          title: I18n.t('react.shared.nav.notifications'),
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_legal'),
      ownSidebar: true,
      children: [
        {
          href: `/terms?form=publisher&locale=${I18n.locale}`,
          title: I18n.t('react.shared.nav.elopage_tc'),
          target: '_blank',
        },
        {
          href: '/privacy',
          title: I18n.t('react.shared.nav.elopage_privacy_policy'),
          target: '_blank',
        },
      ],
    },
  ],
  ablefy_affiliate: [
    {
      title: I18n.t('react.shared.nav.general'),
      ownSidebar: true,
      children: [
        {
          href: ELO_PUBLISHER_PROFILE_PATH,
          title: I18n.t('react.shared.nav.profile'),
        },
        {
          href: ELO_PUBLISHER_BANK_ACCOUNTS_PATH,
          title: I18n.t('react.shared.nav.settings_tab.bank_accounts'),
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_legal'),
      ownSidebar: true,
      children: [
        {
          href: `/terms?form=ablefy_affiliate&locale=${I18n.locale}`,
          title: I18n.t('react.shared.nav.elopage_tc'),
          key: 'terms',
          target: '_blank',
        },
        {
          href: '/privacy',
          title: I18n.t('react.shared.nav.elopage_privacy_policy'),
          key: 'privacy',
          target: '_blank',
        },
      ],
    },
  ],
  team_member: [
    {
      title: I18n.t('react.shared.nav.general'),
      ownSidebar: true,
      children: [
        {
          href: TEAM_MEMBER_PROFILE_PATH,
          title: I18n.t('react.shared.nav.profile'),
          visibility: ['business', 'powerSeller'],
          key: 'profile',
        },
        {
          href: TEAM_MEMBER_BANK_ACCOUNTS_PATH,
          title: I18n.t('react.shared.nav.settings_tab.bank_accounts'),
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.notifications'),
          href: TEAM_MEMBER_NOTIFIC_SETTINGS_PATH,
          icon: 'fas fa-bell',
          type: 'notifications',
          visibility: ['business', 'powerSeller'],
          withLabel: true,
          isNotific: true,
          key: NOTIFIC_SETTINGS_PERMISSION_KEY,
        },
        {
          href: '/cabinet/integrations',
          title: I18n.t('react.shared.nav.settings_tab.integration'),
          visibility: ['business', 'powerSeller'],
          key: 'integrations',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payment_setup'),
      ownSidebar: true,
      useSellerLegalForm: true,
      visibility: ['powerSeller'],
      key: 'dunning',
      children: [
        {
          title: I18n.t('react.shared.nav.claim_strategy'),
          href: '/cabinet/claim_strategy',
          useSellerLegalForm: true,
          visibility: ['powerSeller'],
          key: 'dunning',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_legal'),
      ownSidebar: true,
      children: [
        {
          href: `/terms?form=team_member&locale=${I18n.locale}`,
          title: I18n.t('react.shared.nav.elopage_tc'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
        {
          href: '/privacy',
          title: I18n.t('react.shared.nav.elopage_privacy_policy'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.team_account'),
      ownSidebar: true,
      children: [
        {
          href: '/cabinet/fonts',
          title: I18n.t('react.shared.nav.custom_font'),
          visibility: ['business', 'powerSeller'],
          key: 'fonts',
        },
        {
          href: '/cabinet/billing',
          title: I18n.t('react.shared.nav.billing'),
          visibility: ['business', 'powerSeller'],
          key: 'billing',
        },
      ],
    },
  ],
  payer: [
    {
      title: I18n.t('react.shared.nav.help'),
      href: 'https://support.ablefy.io/payer',
      icon: 'fas fa-question-circle',
      target: '_blank',
      differentBundle: true,
    },
  ],
  sales_team_member: [
    {
      title: I18n.t('react.shared.nav.general'),
      ownSidebar: true,
      children: [
        {
          href: SALES_TEAM_MEMBER_PROFILE_PATH,
          title: I18n.t('react.shared.nav.profile'),
          visibility: ['business', 'powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.technical_legal'),
      ownSidebar: true,
      children: [
        {
          href: `/terms?form=team_member&locale=${I18n.locale}`,
          title: I18n.t('react.shared.nav.elopage_tc'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
        {
          href: '/privacy',
          title: I18n.t('react.shared.nav.elopage_privacy_policy'),
          visibility: ['business', 'powerSeller'],
          target: '_blank',
        },
      ],
    },
  ],
}

export default settingMenuConfigs
